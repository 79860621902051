(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name referees.referee.controller:RefereeCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.informespublic')
    .controller('informespublicCtrl', informespublicCtrl);

  function informespublicCtrl(resultats, partits,$scope,$state,$mdDialog, $http,EnvironmentConfig) {
   var vm = this;
  
   $scope.partidos=[[]];
   var b=[[]];
   
   partits.forEach(function(p,i){
      //a[i]= resultats.query({idPartit: p.idPartido},function(){
        // b[i].elocal=a[i].elocal;
      // });
      $http({
                            url: EnvironmentConfig.API+"/valoracions/"+p.idPartido+"/2",
                            method: "GET"
                        }).success(function (data, status, headers, config) {
                            
                            //copy the data we get to our items array. we need to use angular.copy so that
                            //angular can track the object and bind it automatically.
                            //angular.copy(data, $scope.partidos);
                            for(var i=0;i<data.length;i++){
                               
                            
                            var b1=[];
                           ;
                            b1.apellido=p.apellido;
                          
                            if (p.email===null){
                                 b1.nombre="tècnic/a Ajuntament";
                                 b1.email=p.poblacion;
                            }else{
                                 b1.nombre=p.nombre;
                                 b1.email=p.email;
                               }
                           
                            b1.poblacion=p.poblacion;
                             
                           
                            
                            b1.elocal=data[i].elocal;
                            b1.evisitant=data[i].evisitante;
                           
                            b1.item_Valorado=data[i].item_Valorado;
                            b1.vporc=data[i].vporc;
                           
                            b1.id=data[i].idPartido;
                            b1.fecha=data[i].fecha;
                               $scope.partidos.push(b1);
                           }
                        }).error(function (data, status, headers, config) {
                            //something went wrong
                         
                        });
                        
     
   });
   // angular.copy(a, $scope.partidos);
   
  }
}());

